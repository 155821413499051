.root {
  padding: 10px 15px 9px;
  border: 0.5px solid #dedede;
  box-shadow: 2px 2px 5px rgba(0, 0, 0, 0.1);
  border-radius: 3px;
  margin: 0;

  font-weight: 500;
  font-size: 15px;
  line-height: 18px;
  letter-spacing: 0.065em;

  color: #ffffff;
  cursor: pointer;
}

.main {
  background: #193867;
}

.outline {
  background: #ffffff;
  color: #515151;
}

.root:disabled,
.disabled {
  cursor: not-allowed;
}

.root.main:disabled,
.root.disabled {
  background: #cdcdcd;
}

.root.outline:disabled,
.root.disabled {
  font-weight: 300;
}

.disabledBtn {
  background: #ffffff;
  border: 0.5px solid #dedede;
  box-sizing: border-box;
  box-shadow: 2px 2px 5px rgba(0, 0, 0, 0.1);
  border-radius: 3px;
  opacity: 0.5;
}
