.root {
  width: 100%;
  border: 0.5px solid #dedede;
  border-radius: 3px;
  padding: 10px 15px 9px;
  color: #515151;
}

.root::placeholder {
  color: #9e9e9e;
}
