.DayPicker-Day--selected:not(.DayPicker-Day--disabled):not(.DayPicker-Day--outside) {
  background-color: #193867;
  border: 1px solid #193867;
  color: white;
}

.DayPicker-Day {
  border-radius: 0 !important;
  outline: none;
}
/* .DayPicker-wrapper.DayPicker-Months  */
.DayPicker-Month {
  width: 100%;
  margin: 0;
  margin-top: 1em;
}

.DayPicker-Day--saturday {
  color: #ffc107;
  background-color: #fffdee;
}

.DayPicker-Day--sunday:before {
  content: 'Работает только офис на Куреневской 16';
  position: absolute;
  width: 112px;
  top: 50%;
  left: -59px;
  transform: translate(-50%, -50%);
  cursor: pointer;
  visibility: hidden;
  color: white;
  background-color: rgba(0, 0, 0, 0.9);
  padding: 8px 15px;
  font-size: 14px;
  border-radius: 3px;
  z-index: 10000;
}
.DayPicker-Day--sunday:hover:before {
  visibility: visible;
}

.DayPicker-Day--sunday:after {
  content: '';
  position: absolute;
  border-top: 5px solid transparent;
  border-bottom: 5px solid transparent;
  border-left: 6px solid rgba(0, 0, 0, 0.9);
  top: 10px;
  left: 8%;
  margin-left: -8px;
  visibility: hidden;
  z-index: 10000;
}
.DayPicker-Day--sunday:hover:after {
  visibility: visible;
}

.DayPicker {
  width: 100%;
}
