.root {
  font-style: normal;
  font-weight: normal;
  letter-spacing: normal;
  margin: 0;
  font-size: 15px;
  line-height: 18px;
  color: #515151;
}

.root.block {
  display: block;
}

.root.flex {
  display: flex;
}

.root.inline-block {
  display: inline-block;
}

.root.inline-flex {
  display: inline-flex;
}

.root.h2 {
  font-size: 18px;
  line-height: 22px;
  color: #193867;
}

.root.option {
  font-size: 15px;
  line-height: 18px;
  color: #515151;
}

.root.optionLight {
  font-weight: 300;
  font-size: 15px;
  line-height: 24px;
  color: #000000;
}

.root.caption {
  font-size: 15px;
  line-height: 22px;

  color: rgba(81, 81, 81, 0.5);
}

.root.label {
  color: #193867;
}

.root.left {
  text-align: left;
}

.root.center {
  text-align: center;
}

.root.right {
  text-align: right;
}

.root.justify {
  text-align: justify;
}

.root.noWrap {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
