.root {
  background: #ffffff;
  border: 0.5px solid #dedede;
  border-radius: 3px;
}

.up {
  transform: rotate(180deg);
}

.indicatorsContainer {
  padding: 15px;
}

.placeholder {
  font-style: normal;
  font-weight: 300;
  font-size: 15px;
  line-height: 18px;
  color: #9e9e9e;
}

.option {
  padding: 5px 14px;
  margin-bottom: 5px;
}

[class].option:active,
[class].optionSelected,
[class].optionFocused {
  background: rgba(182, 182, 182, 0.1);
  border-radius: 3px;
}

.option:last-child {
  margin-bottom: 0;
}

[class].menuList {
  padding: 0;
}

[class].menu {
  position: relative;
  border: none;
  box-shadow: none;
  margin-top: 0;
  margin-bottom: 10px;
}

[class].control {
  border: none;
  box-shadow: none;
}

.root.optionCenter .option {
  text-align: center;
}

[class].root.singleValueCenter .singleValue {
  text-align: center;
  width: 100%;
}
