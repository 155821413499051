.root {
  width: 360px;
  margin: 0 0 40px;
  padding: 15px;
}

@media (max-width: 479px) {
  .root {
    margin: 0 0 60px;
    padding: 15px 10px 20px 25px;
  }
}

.submitButton {
  width: 100%;
}

.disabledBtn {
  cursor: no-drop;
  background-color: #cdcdcd;
}
