.buttonWrapper {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 15px;
}

.buttonWrapper button {
  flex-grow: 1;
  flex-shrink: 0;
  margin-right: 15px;
}

[class].disabledBtn {
  background: #ffffff;
  border: 0.5px solid #dedede;
  box-sizing: border-box;
  box-shadow: 2px 2px 5px rgba(0, 0, 0, 0.1);
  border-radius: 3px;
  opacity: 0.5;
}

.buttonWrapper button:last-child {
  margin-right: 0;
  max-width: 82px;
}

.dateTimeTitle {
  margin-bottom: 15px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.questionIcon {
  margin-left: 10px;
}

.choseDate {
  position: relative;
  right: 0;
  max-width: 158px;
}

.dayPikerSection {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  margin-bottom: 15px;
}

.dateTimeNotion {
  border-left: 0.75px solid rgba(81, 81, 81, 0.25);
  padding-left: 10px;
}
