.group {
  margin-bottom: 15px;
}

.workTime {
  display: flex;
  flex-direction: column;

  border-left: 0.75px solid rgba(81, 81, 81, 0.25);
  padding-left: 15px;
}
