.input {
  text-align: center;
  margin-bottom: 15px;
}

.button {
  margin-bottom: 15px;
  min-width: 158px;
}

.group {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
}

.link {
  color: #193867;
  font-weight: 'bold';
  text-decoration: 'underline';
  cursor: pointer;
}

.disabled {
  cursor: auto;
  opacity: 0.5;
  pointer-events: none;
}

.flex {
  display: flex;
}

.icon {
  top: 5px;
  position: relative;
}
