.dropDownTitle {
  position: relative;
  cursor: pointer;
  padding-right: 5px;
}

.dropDownWrapper {
  position: relative;
}
.dropDownContainer {
  position: absolute;
  top: 25px;
  left: -15px;
  min-width: 275px;
  background-color: #fff;
  padding: 10px 15px;
  border: 0.5px solid #dedede;
  box-shadow: 2px 2px 5px rgb(0 0 0 / 10%);
  border-radius: 3px;
  z-index: 2;
}

.up {
  transform: rotate(180deg);
}

.row {
  display: flex;
  color: #0000;
}

.collLeft {
  min-width: 70px;
  margin-right: 20px;
  font-weight: 300;
}

.questionIcon {
  margin-left: 10px;
  position: relative;
  top: 5px;
}
