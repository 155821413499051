.root {
  margin-bottom: 20px;
}

.deliveryType {
  margin-bottom: 15px;
  display: flex;
  align-items: center;
}

.deliveryType button {
  flex-grow: 1;
  flex-shrink: 0;
  margin-right: 15px;
}

.deliveryType button:last-child {
  margin-right: 0;
}
