.successScreenWrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100%;
  color: #193867;
  text-align: center;
  height: 100vh;
}
.svg {
  font-size: 120px;
  margin-bottom: 20px;
}

.successTitle {
  font-weight: 500;
  font-size: 20px;
  line-height: 24px;
  margin-bottom: 20px;
  color: #193867;
}

.successSubtitle {
  font-weight: 300;
  font-size: 18px;
  line-height: 24px;
  color: #193867;
}
